import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      padding: "1rem",
      backgroundColor: COLORS.lightGray,
    }}
  >
    <div
      style={{
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 2rem",
        fontSize: ".85rem",
      }}
    >
      <div style={{ color: COLORS.gray, maxWidth: "600px" }}>
        <p>
          The information presented at this site should not be construed to be
          formal legal advice nor the formation of a lawyer/client relationship.
          This web site is designed for general information and is managed by{" "}
          <a
            style={{ textDecoration: "none" }}
            href="https://webandmobile.software"
          >
            Web & Mobile Software
          </a>
        </p>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
