import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import LandingPageForm from "./LandingPageForm"
import Footer from "./Footer"
import "../styles/default.css"
import img from "../images/photo.jpg"
import styled from "styled-components"

const Container = styled.div`
  margin: 20px auto;
  display: flex;
  align-items: center;
  padding: "4rem 1rem";
  max-width: 960px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 0 1rem;
`

const Title = styled.h1`
  text-align: center;
  font-size: 1.25rem;
`

const Photo = styled.div`
  width: "100%";
`

const CompanyUrl = styled.div`
  padding-bottom: 30px;
  text-align: center;
  a {
    color: black;
    text-decoration: none;
  }
`

const Description = styled.p`
  font-size: 15px;
`

const ContactUs = styled.div`
  flex: 1;
  padding: 0 1rem;
`

const ContactUsNow = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
  padding: 40px 0 40px;
  text-align: center;
  h2 {
    margin: 40px 0;
  }
  a {
    color: black;
    font-size: 1rem;
    text-decoration: none;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            phoneNumber
            content {
              title
              companyName
              companyUrl
              description
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container>
          <Content>
            <Title>{data.site.siteMetadata.content.title}</Title>
            <CompanyUrl>
              <a href={`${data.site.siteMetadata.content.companyUrl}`}>
                {data.site.siteMetadata.content.companyName}
              </a>
            </CompanyUrl>
            <Photo>
              <img
                src={img}
                alt="outlines of shapes and confetti in the background "
              />
            </Photo>

            <Description>
              {data.site.siteMetadata.content.description}
            </Description>
          </Content>
          <ContactUs>
            <ContactUsNow>
              <h2>Contact Us For Help:</h2>
              <a href={`tel:+1${`${data.site.siteMetadata.phoneNumber}`}`}>
                <span>{data.site.siteMetadata.phoneNumber}</span>
              </a>
            </ContactUsNow>
            <LandingPageForm />
          </ContactUs>
        </Container>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
