import React from "react"
// import { render } from "react-dom";
import { Form, Field } from "react-final-form"
import axios from "axios"
import styled from "styled-components"
import Button from "./Button"

const onSubmit = async values => {
  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        name: "firstname",
        value: values.firstname,
      },
      {
        name: "lastname",
        value: values.lastname,
      },
      {
        name: "email",
        value: values.email,
      },
      {
        name: "company",
        value: values.company,
      },
      {
        name: "message",
        value: values.message,
      },
    ],
    context: {
      hutk: "6f05eb3913e64d74873508c95d6388a2",
      pageUri: "tampalegalgroup.com",
      pageName: "Contact | LAW OFFICE OF JOCIC & KOULIANOS",
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text:
          "I agree to allow Web & Mobile Software to store and process my personal data.",
        communications: [
          {
            value: true,
            subscriptionTypeId: 999,
            text:
              "I agree to receive marketing communications from Web & Mobile Software.",
          },
        ],
      },
    },
  }

  axios
    .post(
      "https://api.hsforms.com/submissions/v3/integration/submit/8610064/4705d0ad-ec60-4c51-8ee5-6c64716b96da",
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function(response) {
      console.log("👉 Returned data:", response)
      alert(response.data.inlineMessage)
    })
    .catch(function(error) {
      console.log(`😱 Axios request failed: ${error}`)
    })
}

const LandingPageForm = () => (
  <ContactForm>
    <div>
      <Form
        onSubmit={onSubmit}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Required"
          }
          if (!values.firstname) {
            errors.firstname = "Required"
          }
          if (!values.lastname) {
            errors.lastname = "Required"
          }
          if (!values.message) {
            errors.message = "Required"
          }
          // if (!values.age) {
          //   errors.age = 'Required'
          // } else if (isNaN(values.age)) {
          //   errors.age = 'Must be a number'
          // } else if (values.age < 18) {
          //   errors.age = 'No kids allowed'
          // }
          return errors
        }}
        render={({ handleSubmit, reset, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Field name="firstname">
              {({ input, meta }) => (
                <InputBox>
                  <Label>First Name:</Label>
                  <Input
                    {...input}
                    type="text"
                    // placeholder="What is your first name?"
                  />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </InputBox>
              )}
            </Field>
            <Field name="lastname">
              {({ input, meta }) => (
                <InputBox>
                  <Label>Last Name:</Label>
                  <Input
                    {...input}
                    type="text"
                    // placeholder="What is your last name?"
                  />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </InputBox>
              )}
            </Field>
            <Field name="email">
              {({ input, meta }) => (
                <InputBox>
                  <Label>Email:</Label>
                  <Input
                    {...input}
                    type="text"
                    // placeholder="youremail@address.com"
                  />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </InputBox>
              )}
            </Field>
            <Field name="company">
              {({ input, meta }) => (
                <InputBox>
                  <Label>Company:</Label>
                  <Input
                    {...input}
                    type="text"
                    // placeholder="What company is this for?"
                  />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </InputBox>
              )}
            </Field>
            <Field name="message">
              {({ input, meta }) => (
                <div className="message">
                  <Label>Message:</Label>
                  <Textarea {...input} type="text" />
                  {meta.error && meta.touched && <span>{meta.error}</span>}
                </div>
              )}
            </Field>
            {/* <Field name="age">
                {({ input, meta }) => (
                  <div>
                    <label>Age</label>
                    <input {...input} type="text" placeholder="Age" />
                    {meta.error && meta.touched && <span>{meta.error}</span>}
                  </div>
                )}
              </Field> */}
            <div className="buttons">
              <Button
                type="submit"
                disabled={submitting}
                variant="primary"
                size="lg"
                className="button"
              >
                Submit
              </Button>
            </div>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  </ContactForm>
)

export default LandingPageForm

const ContactForm = styled.div`
  background: #ffffff;
  h2 {
    margin: 80px 0;
    font-size: 1.25rem;
    font-weight: bold;
    border-bottom: 3px solid #118df0;
    text-transform: uppercase;
    width: fit-content;
  }
  .message {
    span {
      color: red;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin: 60px 0 80px;
  }
  .button {
    padding: 20px 20%;
  }
  @media (max-width: 768px) {
    h2 {
      font-size: 1.25rem;
    }
  }
`
const InputBox = styled.div`
  padding: 0;
  margin: 0 0 20px;
  border-bottom: 1px solid #000000;
  display: flex;
  span {
    color: red;
  }
`

const Label = styled.label`
  padding: 10px 10px 5px 0;
  font-size: 1.25rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`
const Input = styled.input`
  border: 0 none;
  background: none;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  outline: none;
  ::placeholder {
    color: #666;
  }
  @media (max-width: 768px) {
    font-size: 1.25rem;
    ::placeholder {
      color: #666;
    }
`

const Textarea = styled.textarea`
  border: 0 none;
  background: none;
  font-size: 1.25rem;
  font-weight: 400;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  outline: none;
  border: 1px solid #000000;
  padding: 20px;
  ::placeholder {
    color: #666;
  }
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`
